import axios from "axios";
import https from "https";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Authentication: localStorage.getItem("token"),
    },
  },
});

export const instanceNode = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    common: {
      Authentication: localStorage.getItem("token"),
    },
  },
  httpsAgent: new https.Agent({ rejectUnauthorized: false }),
});
