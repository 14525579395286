import styled from "styled-components";

export const Servico = styled.div`
  width: 100%;
  margin-top: 20px;
  /* padding: 15px; */
  display: inline-block;
  border: 1px solid black;
`;
export const Information = styled.div`
  margin: 10px 0px;
  /* padding: 15px; */
  /* border: 1px solid #ccc; */
  line-height: 1.6;
  /* background: #eeeeee; */
  font-size: 1.15rem;
`;
export const TitleInformation = styled.h2`
  margin: 0px;
  padding: 0px 0px 10px 0px;
  font-weight: bold;
`;

export const Content = styled.div`
  div {
    padding: 3px 15px;
  }

  div:nth-child(even) {
    background-color: #cccccc;
  }
  div:nth-child(odd) {
    background-color: #eeeeee;
  }
`;

export const Title = styled.h4`
  margin: 0px;
  padding: 13px;
  font-weight: bolder;
  color: #2862bb;
  text-transform: uppercase;
`;

export const MainTitle = styled.h2`
  margin: 0px;
  padding-top: 13px;
  font-weight: bolder;
  color: #2862bb;
  text-transform: uppercase;
`;

export const BlueEffect = styled.div`
  position: absolute;
  z-index: -1;
  right: 0px;
  left: 0px;
  top: 0px;
  height: 130px;
  background: #256cc8;
`;
