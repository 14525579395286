import React from "react";
import { useHistory } from "react-router-dom";

import Card from "./Card";
import { ConetnConteiner, Error } from "../../styles/Inicio/index";

function Conteudo({ providers, providersError }) {
  const history = useHistory();
  return (
    <ConetnConteiner>
      {providersError ? (
        <Error>
          <h4>Falha ao Carregar Prestadores</h4>
        </Error>
      ) : providers && providers.length > 0 ? (
        providers.map((provider, index) => (
          <div
            key={provider.ProCodigo}
            onClick={() => history.push(`/provider/${provider.ProCodigo}`)}
          >
            <Card provider={provider} />
          </div>
        ))
      ) : (
        <center>
          <h4>Nenhum Prestador Encontrado</h4>
        </center>
      )}
    </ConetnConteiner>
  );
}

export default React.memo(Conteudo);
