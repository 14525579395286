import React from "react";

import { InputWithMask } from "../../components/WebMedicina/InputWithMask";
import { Input } from "../../styles/Inicio";

export default function Contato({
  control,
  errors,
  register,
  getValues,
  trigger,
}) {
  const maskTel = "(99) 99999-9999";
  const oneIsFilled = (v) => {
    const singleValue = getValues(["telefone", "DDD"]);

    if (
      (singleValue.telefone === undefined ||
        singleValue.telefone === null ||
        (singleValue.telefone &&
          singleValue.telefone.replace(/\D/g, "").length <= 0)) &&
      !v
    ) {
      return "Campo obrigatório";
    }

    return undefined;
  };

  const oneIsFilledTel = (v) => {
    const singleValue = getValues(["email"]);

    if (!singleValue.email || (v && v.replace(/\D/g, "").length > 0)) {
      return v &&
        v.replace(/\D/g, "").length === maskTel.replace(/\D/g, "").length
        ? undefined
        : "Campo obrigatorio.";
    }

    return undefined;
  };

  const oneIsFilledDDD = (v) => {
    const singleValue = getValues(["email"]);

    if (!singleValue.email || (v && v.replace(/\D/g, "").length > 0)) {
      return v && v.replace(/\D/g, "").length === 2
        ? undefined
        : "Campo obrigatorio.";
    }

    return undefined;
  };

  return (
    <>
      <label style={{ color: errors.email ? "red" : "black" }} htmlFor="nome">
        Email
      </label>
      <Input
        style={{ borderColor: errors.email ? "red" : "black" }}
        ref={register({
          validate: {
            oneIsFilled,
          },
        })}
        name="email"
        type="email"
        onChange={(e) => trigger(["telefone", "DDD"])}
      ></Input>
      {errors.email && (
        <div style={{ color: "red", marginBottom: 15 }}>Campo obrigatório</div>
      )}
      {/* 
      <div style={{ flex: 1 }}>
        <InputWithMask
          control={control}
          mask="(99)"
          name="DDD"
          error={errors.DDD}
          label="DDD"
          defaultRule={false}
          rules={{ oneIsFilledDDD }}
          changeOn={(e) => trigger(["email"])}
        />
      </div> */}
      <div style={{ flex: 1 }}>
        <InputWithMask
          type="tel"
          control={control}
          mask={maskTel}
          name="telefone"
          error={errors.telefone}
          label="Telefone"
          defaultRule={false}
          rules={{ oneIsFilledTel }}
          changeOn={(e) => trigger(["email"])}
        />
      </div>
    </>
  );
}
