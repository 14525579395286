import React from "react";

const Telefone = ({ numero, ddd }) => {
  return (
    <span>
      &nbsp; ({ddd}) {numero}
    </span>
  );
};

export default Telefone;
