import React from "react";
import Spinner from "react-spinner-material";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import NovaCidade from "./views/Inicio/NovaCidade";
import Feegow from "./views/WebMedicina/Feegow";
import Categoria from "./views/Inicio/Categoria";
import ProviderList from "./views/Prestador/ProviderList";
import Carterinha from "./views/Inicio/Carterinha";
// import Inicio from "./views/Inicio";
// import Prestador from "./views/Prestador";

let Inicio = React.lazy(() => import("./views/Inicio"));
let Sucesso = React.lazy(() => import("./views/WebMedicina/Sucesso"));
let NovoAgendamento = React.lazy(() =>
  import("./views/WebMedicina/NovoAgendamento")
);
let LandingPage = React.lazy(() =>
  import("./views/WebMedicina/LandingPage")
);
let VerAgendamentos = React.lazy(() =>
  import("./views/WebMedicina/VerAgendamentos")
);
let WebMedicina = React.lazy(() => import("./views/WebMedicina"));
let Prestadores = React.lazy(() => import("./views/WebMedicina/Prestadores"));
let Prestador = React.lazy(() => import("./views/Prestador"));
let TipoAgendamento = React.lazy(() =>
  import("./views/WebMedicina/TipoAgendamento")
);
let CancelarAgendamento = React.lazy(() =>
  import("./views/WebMedicina/CancelarAgendamento")
);
let EditarAgendamento = React.lazy(() =>
  import("./views/WebMedicina/EditarAgendamento")
);
let EditarConsulta = React.lazy(() =>
  import("./views/WebMedicina/EditarConsulta")
);
let EditarFuncionario = React.lazy(() =>
  import("./views/WebMedicina/EditarFuncionario")
);
let SucessoEdit = React.lazy(() => import("./views/WebMedicina/SucessoEdit"));
let CheckFree = React.lazy(() => import("./views/WebMedicina/CheckFree"));

const loading = () => (
  <center>
    <br />
    <Spinner radius={40} color={"#2862bb"} stroke={4} visible={true} />
  </center>
);

function App() {
  const [userDataProsesmt, setUserDataProsesmt] = React.useState({});
  const [token, setToken] = React.useState(null);
  const [needToPay, setNeedToPay] = React.useState(false);

  const [states, setStates] = React.useState(null);
  const [citys, setCitys] = React.useState(null);

  const [stateSelected, setStateSelected] = React.useState(null);
  const [citySelected, setCitySelected] = React.useState(null);

  const [providers, setProviders] = React.useState(null);

  const [exam, setExam] = React.useState(null);

  const [formData, setFormData] = React.useState({
    nome: null,
    cpf: "",
    rg: "",
    dataNascimento: "",
    sexo: "",
  });

  const [timeData, setTimeData] = React.useState({
    selectedTime: null,
    selectedDate: null,
  });

  const [webMedicicaCPF, setWebMedicicaCPF] = React.useState();
  const [webMedicicaCNPJ, setWebMedicicaCNPJ] = React.useState();
  const [webMedicicaNome, setWebMedicicaNome] = React.useState();
  const [institutoSaude, setInstitutoSaude] = React.useState();
  const [institutoData, setInstitutoData] = React.useState();
  const [globalType, setGlobalType] = React.useState();
  const [globalAmbient, setGlobalAmbient] = React.useState();

  return (
    <div className="App">
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Redirect to="/inicio/3333/111" />}
            />
            <Route
              exact
              path="/webmedicina/landing/:cpf/:cnpj"
              render={(props) => (
                <WebMedicina
                  institutoSaude={institutoSaude}
                  {...props}
                />
              )}
            />
            <Route
              exact 
              path="/webmedicina/inicio/:cpf/:cnpj"
              render={(props) => (
                <LandingPage
                  institutoData={institutoData}
                  setInstitutoData={setInstitutoData}
                  setUserDataProsesmt={setUserDataProsesmt}
                  setWebMedicicaCPF={setWebMedicicaCPF}
                  setWebMedicicaCNPJ={setWebMedicicaCNPJ}
                  setInstitutoSaude={setInstitutoSaude}
                  institutoSaude={institutoSaude}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/carteirinha/:cpf/:cnpj"
              render={(props) => (
                <Carterinha
                  webMedicicaNome={webMedicicaNome}
                  webMedicicaCPF={webMedicicaCPF}
                  institutoSaude={institutoSaude}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/prestadores"
              render={(props) => <Prestadores institutoSaude={institutoSaude} userDataProsesmt={userDataProsesmt} {...props} />}
            />
            <Route
              exact
              path="/webmedicina/tipoAgendamento/:id/:unidadeId"
              render={(props) => <TipoAgendamento {...props} />}
            />
            <Route
              exact
              path="/webmedicina/novoAgendamento/:stepvalue/:id/:unidadeId"
              render={(props) => (
                <NovoAgendamento
                  userDataProsesmt={userDataProsesmt}
                  formData={formData}
                  setFormData={setFormData}
                  timeData={timeData}
                  setTimeData={setTimeData}
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  needToPay={needToPay}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/check"
              render={(props) => (
                <CheckFree
                  institutoData={institutoData}
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  setNeedToPay={setNeedToPay}
                  institutoSaude={institutoSaude}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/feegow/:cpf/:cnpj"
              render={(props) => (
                <Feegow
                  userDataProsesmt={userDataProsesmt}
                  formData={formData}
                  setFormData={setFormData}
                  timeData={timeData}
                  setTimeData={setTimeData}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/verAgendamentos"
              render={(props) => (
                <VerAgendamentos
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/cancelarAgendamento/:idFeegow/:id/:date"
              render={(props) => (
                <CancelarAgendamento
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/editarAgendamento/:idFeegow/:id/:date/:idEmployee"
              render={(props) => (
                <EditarAgendamento
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/editarConsulta/:idFeegow/:id/:date"
              render={(props) => (
                <EditarConsulta
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/editarFuncionario/:idEmployee"
              render={(props) => (
                <EditarFuncionario
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/sucessoEdit"
              render={(props) => (
                <SucessoEdit
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/webmedicina/sucesso/:type/:barcode/:isInad/:idPay/:status"
              render={(props) => (
                <Sucesso
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  institutoSaude={institutoSaude}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/inicio/:cpf/:cnpj/:type?/:ambient?"
              render={(props) => (
                <Inicio
                  globalType={globalType}
                  setGlobalType={setGlobalType}
                  setGlobalAmbient={setGlobalAmbient}
                  globalAmbient={globalAmbient}
                  {...props}
                  states={states}
                  setStates={setStates}
                  citys={citys}
                  setCitys={setCitys}
                  setToken={setToken}
                  stateSelected={stateSelected}
                  setStateSelected={setStateSelected}
                  citySelected={citySelected}
                  setCitySelected={setCitySelected}
                  token={token}
                  providers={providers}
                  setProviders={setProviders}
                  exam={exam}
                  setExam={setExam}
                  setWebMedicicaCPF={setWebMedicicaCPF}
                  setWebMedicicaCNPJ={setWebMedicicaCNPJ}
                  setWebMedicicaNome={setWebMedicicaNome}
                  setInstitutoSaude={setInstitutoSaude}

                />
              )}
            />
            <Route
              exact
              path="/provider/:id"
              render={(props) => (
                <Prestador
                  {...props}
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  setToken={setToken}
                  token={token}
                />
              )}
            />
            <Route
              exact
              path="/categoria/:id/:title/:estado/:cidade"
              render={(props) => (
                <Categoria
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/providerList/:id/:estado/:cidade/:title"
              render={(props) => (
                <ProviderList
                  cpf={webMedicicaCPF}
                  cnpj={webMedicicaCNPJ}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/solicitacao"
              render={(props) => <NovaCidade {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
