import styled from "styled-components";

export const Title = styled.h3`
  margin: 0px;
  padding: 0px 0px 10px 0px;
  font-weight: bold;
`;

export const CardStyle = styled.div`
  margin: 10px 0px;
  padding: 15px;
  /* border: 1px solid #ccc; */
  line-height: 1.6;
  background: #eeeeee;
  transition: margin 0.3s ease-in-out;

  ${(props) =>
    props.active &&
    `
    margin: 20px;
  `};
`;

export const ItemStyle = styled.div`
  margin: 10px 0px;
  padding: 10px 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  transition: margin 0.3s ease-in-out;

  ${(props) =>
    props.active &&
    `
    padding: 20px 15px;
    margin: 0px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  `};
`;

export const ItemContent = styled.div`
  display: flex;
  position: relative;
  ${(props) =>
    props.active &&
    `
    flex-direction: column;
    margin: 0px 10px;
  `};
`;

export const EnderecoStyle = styled.p`
  line-height: 15px;
`;
