import React from "react";
import { Link } from "react-router-dom";
import { ButtonFixed } from "../../styles/Inicio";

export default function Feegow({ match }) {
  const { cpf, cnpj } = match.params;

  return (
    <div>
      <center>
        <h1>Consulta</h1>
      </center>
      <center>
        <iframe
          id="feegowIframe"
          title="Feegow"
          src="https://api.feegow.com.br/patient-interface/PN5K/login"
          width="95%"
          height="710"
          allow="camera;microphone"
          // style="border:none;"
        ></iframe>
      </center>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/webmedicina/landing/${cpf}/${cnpj}`}
      >
        <ButtonFixed type="button">Sair da consulta</ButtonFixed>
      </Link>
    </div>
  );
}
