import React from "react";

import {
  Form,
  Conteiner,
  ButtonStepper,
  ConteinerInterno,
} from "../../styles/Inicio";
import { useForm } from "react-hook-form";

import { Input, Textarea } from "../../styles/Inicio";
import Contato from "../../components/WebMedicina/Contato";

export default function NovaCidade() {
  const [step, setStep] = React.useState(1);
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    trigger,
  } = useForm();
  return (
    <Conteiner>
      {step === 1 && (
        <Form onSubmit={handleSubmit((data) => setStep(2))}>
          <center>
            <h1>Solicitar Novo Posto</h1>
          </center>
          <label
            style={{ color: errors.nome ? "red" : "black" }}
            htmlFor="cidade"
          >
            Cidade
          </label>
          <Input
            style={{ borderColor: errors.cidade ? "red" : "black" }}
            ref={register({ required: true })}
            name="cidade"
          ></Input>
          {errors.cidade && (
            <div style={{ color: "red", marginBottom: 15 }}>
              Campo obrigatório
            </div>
          )}
          <Contato
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
            control={control}
            errors={errors}
            register={register}
          />
          <label
            style={{ color: errors.nome ? "red" : "black" }}
            htmlFor="cidade"
          >
            Quais exames você deseja que sejam adicionados?
          </label>
          <Textarea
            style={{ borderColor: errors.exames ? "red" : "black" }}
            ref={register({ required: true })}
            name="exames"
          />
          {errors.exames && (
            <div style={{ color: "red", marginBottom: 15 }}>
              Campo obrigatório
            </div>
          )}
          <ButtonStepper type="submit" value="Enviar" />
        </Form>
      )}

      {step === 2 && (
        <ConteinerInterno>
          <center>
            <h1>Solicitação enviada com sucesso</h1>{" "}
          </center>
          <ButtonStepper type="button" value="Entendi" />
        </ConteinerInterno>
      )}
    </Conteiner>
  );
}
