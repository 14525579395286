
import React from "react";
import Carteirinha_SuaSaude_VF from "../../assets/Carteirinha_SuaSaude_VF_Gerale.png";
import InstitutoDeSaude from "../../assets/InstitutoDeSaude.png";
import { ButtonStepper } from "../../styles/Inicio";


export default function Carterinha({
  history,
  match,
  webMedicicaNome,
  webMedicicaCPF,
  institutoSaude,
}) {
  const { cpf, cnpj } = match.params;

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100vw",
          heigth: "100vh",
          justifyContent: "center",
        }}
      >
        <div style={{ position: "relative" }}>
          <img
            style={{ maxWidth: "94vw", margin: "60px 0px 0px 0px" }}
            src={Carteirinha_SuaSaude_VF}
          />
          {/* {institutoSaude && <img src={InstitutoDeSaude} style={{position: "absolute", width: "20%", bottom: "10%", right: 20}} />} */}
          <div
            style={{
              position: "absolute",
              bottom: "37%",
              left: "5.6%",
              color: "white",
              fontWeight: "lighter",
              fontSize: "clamp(0.1rem, 2.2vw, 1.5rem)",
            }}
          >
            CPF
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "31%",
              left: "5.8%",
              color: "white",
              fontSize: "clamp(0.1rem, 3vw, 2rem)",
            }}
          >
            {webMedicicaCPF?.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            )}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "25%",
              left: "5.8%",
              color: "white",
              fontWeight: "lighter",
              fontSize: "clamp(0.1rem, 2.2vw, 1.5rem)",
            }}
          >
            Nome
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "19%",
              left: "6%",
              color: "white",
              fontSize: "clamp(0.1rem, 3vw, 2rem)",
            }}
          >
            {webMedicicaNome}
          </div>
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <ButtonStepper
          type="button"
          onClick={() => history.push(`/inicio/${cpf}/${cnpj}`)}
          value="Voltar"
        />
      </div>
    </div>
  );
}
