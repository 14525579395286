import React from "react";
import Spinner from "react-spinner-material";

import { Loading } from "../styles/Inicio";

export default function SpinnerLoading() {
  return (
    <Loading>
      <Spinner radius={40} color={"#2862bb"} stroke={4} visible={true} />
    </Loading>
  );
}
