import React from "react";
import { instance } from "../../services/api";
import SpinnerLoading from "../../components/SpinnerLoading";
import { Servico, Content, Title } from "../../styles/Prestador";
import { Conteiner, ConteinerInterno, ButtonFixed } from "../../styles/Inicio";
import { Link } from "react-router-dom";

export default function Categoria({ match, history, cpf, cnpj }) {
  const { id, title, cidade, estado } = match.params;
  const [content, setContent] = React.useState(null);
  React.useEffect(() => {
    instance
      .get(`/health/category/${id}/exams/${estado}/${cidade}`)
      .then((reponse) => {
        setContent(reponse.data);
      });
  }, []);

  const redirecionar = (id) => {
    history.push(`/providerList/${id}/${estado}/${cidade}/${title}`);
  };

  const voltar = () => {
    history.push(`/inicio/${cpf}/${cnpj}`);
  };

  return content ? (
    <Conteiner>
      <ConteinerInterno>
        <center>
          {" "}
          <Title> {title} </Title>{" "}
        </center>
        <Servico>
          <Content>
            {content.map((item) => (
              <div
                key={item.exacodigo}
                onClick={() => redirecionar(item.exacodigo)}
                style={{ padding: 15 }}
              >
                {" "}
                <b>{item.exanomecomercial}</b>
              </div>
            ))}
          </Content>
        </Servico>
        <br />
        <br />
        <br />
        <br />
        <br />
        <ButtonFixed onClick={voltar}>Voltar</ButtonFixed>
      </ConteinerInterno>
    </Conteiner>
  ) : (
    <SpinnerLoading />
  );
}
