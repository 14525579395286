import React from "react";

import { Controller } from "react-hook-form";
import MaskedInput from "react-input-mask";

import { Input } from "../../styles/Inicio";

export const InputWithMask = React.forwardRef(
  ({
    control,
    mask,
    name,
    rules = {},
    error,
    label,
    valid,
    returnRaw = true,
    defaultRule = true,
    onBeforeInputCapture,
    type = "text",
    changeOn = () => {},
    setChange,
  }) => {
    const isValidField = (v) => {
      if (!defaultRule) return undefined;
      return v && v.replace(/\D/g, "").length === mask.replace(/\D/g, "").length
        ? undefined
        : "Campo obrigatorio.";
    };

    return (
      <>
        <label style={{ color: error ? "red" : "black" }} htmlFor={name}>
          {label}
        </label>
        <Controller
          render={({ onChange, onBlur, value }) => {
            return (
              <MaskedInput
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);

                  changeOn({
                    e,
                    valueRaw: e.target.value.replace(/\D/g, ""),
                    valid:
                      e.target.value.replace(/\D/g, "").length ===
                      mask.replace(/\D/g, "").length,
                  });
                }}
                mask={mask}
                alwaysShowMask
                maskChar="_"
              >
                {(props) => {
                  return (
                    <Input
                      // name={name}
                      {...props}
                      type={type}
                      style={{ borderColor: error ? "red" : "black" }}
                    />
                  );
                }}
              </MaskedInput>
            );
          }}
          control={control}
          name={name}
          rules={{
            validate: {
              ...rules,
              inputTelRequired: isValidField,
            },
          }}
        />
        {error && (
          <div style={{ color: "red", marginBottom: 15 }}>
            {error.message ? error.message : "Campo obrigatório"}
          </div>
        )}
      </>
    );
  }
);

export const InputNoMask = React.forwardRef(
  ({ error, register, label, name, rules = {} }) => {
    return (
      <div>
        <label style={{ color: error ? "red" : "black" }} htmlFor="nome">
          {label}
        </label>
        <Input
          style={{ borderColor: error ? "red" : "black" }}
          ref={register(rules)}
          name={name}
        ></Input>
        {error && (
          <div style={{ color: "red", marginBottom: 15 }}>
            Campo obrigatório
          </div>
        )}
      </div>
    );
  }
);
