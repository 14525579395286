import styled from "styled-components";

export const Conteiner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  font-size: 20px;
`;

export const Form = styled.form`
  @media (max-width: 900px) {
    width: 100%;
  }
  width: 50%;
  transition: 0.4s ease-in-out;
`;

export const ConteinerInterno = styled.div`
  @media (max-width: 900px) {
    min-width: 100%;
  }
  min-width: 50%;
  width: 50%;
  transition: 0.4s ease-in-out;
`;

export const ConetnConteiner = styled.form`
  @media (max-width: 900px) {
    width: 100%;
  }
  width: 50%;
  transition: 0.4s ease-in-out;
`;

export const Input = styled.input`
  width: 100%;
  padding: 20px 30px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  font-size: 1.1rem;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 20px 30px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  font-size: 1.1rem;
`;

export const Select = styled.select`
  min-width: 100%;
  padding: 20px 30px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  font-size: 1.1rem;
  background: white;
`;

export const ButtonStepper = styled.input`
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 100%;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 30px;
  height: 45px;
  background-color: #d9544f;
  border: none;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const ButtonFixed = styled.button`
  position: fixed;
  bottom: 10px;
  width: 300px;
  right: calc(50% - 150px);
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 30px;
  height: 45px;
  background-color: #d9544f;
  border: none;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #bf5c54;
  min-height: 50px;
  width: 100%;
  background: #ffd7d4;
  box-sizing: border-box;
  h4 {
    margin-left: 10px;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
`;
