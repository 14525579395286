import React from "react";
import { instance } from "../../services/api";
import SpinnerLoading from "../../components/SpinnerLoading";
import Conteudo from "../Inicio/Conteudo";
import { Conteiner, ConteinerInterno, ButtonFixed } from "../../styles/Inicio";

export default function ProviderList({ match, history, cpf, cnpj }) {
  const { id, estado, cidade, title } = match.params;

  const [providers, setProviders] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setProvidersError] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    instance
      .get(`/health/provider`, {
        params: {
          ExaCodigo: id,
          Cidade: cidade,
          UF: estado,
        },
      })
      .then((responseProvider) => {
        setProviders(responseProvider.data);
        setProvidersError(false);
        setLoading(false);
      })
      .catch((reason) => {
        setLoading(false);
        setProviders([]);
        if (reason.response.status !== 404) {
          setProvidersError(true);
        }
      });
  }, [cidade, estado, id]);

  const voltar = () => {
    // history.push(`/categoria/${id}/${title}/${estado}/${cidade}`);
    history.goBack();
  };

  return loading ? (
    <SpinnerLoading />
  ) : (
    <Conteiner>
      <ConteinerInterno>
        <Conteudo providers={providers} providersError={error} />
        <ButtonFixed
          // style={{ width: "100%", margin: "20px 0px 0px 1px" }}
          onClick={voltar}
        >
          Voltar
        </ButtonFixed>
      </ConteinerInterno>
    </Conteiner>
  );
}
