import React from "react";

import { Title, CardStyle } from "../../styles/Inicio/Card";
import Telefone from "../../components/Telefone";

export default function Card({ provider }) {
  return (
    <CardStyle>
      <Title>{provider.ProNome}</Title>
      {/* <div>
        {provider.ProTipoEndereco} {provider.ProEndereco},
        {provider.ProEnderecoNumero}
      </div> */}
      <h4 style={{ margin: "0px 0px 10px 0px", padding: 0 }}>
        {provider.ProBairro}, {provider.ProCidade} ({provider.ProEstado})
      </h4>
      <div>(Clique para ver mais informações)</div>
      {/* <div>
        Telefones:
        {provider.ProTelefone && (
          <Telefone ddd={provider.ProDDD} numero={provider.ProTelefone} />
        )}
        {provider.ProTelefone2 && (
          <Telefone ddd={provider.ProDDD2} numero={provider.ProTelefone2} />
        )}
      </div> */}
    </CardStyle>
  );
}
